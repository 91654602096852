
import sr1 from './assets/link1.jpg'
import link2 from './assets/link2.jpg'
import link0 from './assets/Link0.jpg'
import link3 from './assets/Link3.jpg'
import link4 from './assets/Link4.jpg'
import link5 from './assets/Link5.jpg'


const Sdata =[

    {
        imgsrc:link0,
        name:"Ganeshji",
        link: "https://amzn.eu/d/bti88nV"
    },
    {
        imgsrc:sr1,
        name:"Chandelier",
        link: "https://amzn.eu/d/2KxUM0z"
    },
    {
        imgsrc:link2,
        name:"Chandelier",
        link: "https://amzn.eu/d/fjGKOyl"
    },
    {
        imgsrc:link3,
        name:" Pumpkin",
        link: "https://amzn.eu/d/3T1Go4j"
    },
    {
        imgsrc:link4,
        name:"Ceiling Pendant",
        link: "https://amzn.eu/d/5VAdyVN"
    },
    {
        imgsrc:link5,
        name:"Watts Cycle",
        link: "https://amzn.eu/d/grZ3ALq"
    }
];
export default Sdata;